/**
* Created by tudou on 2020/4/20 21:32.
*/
<template>
  <div class="base-content">
    <form-frame :submit-visible="false" :reset-visible="false">
      <div slot="info" v-if="paramsId">
        <span class="mr-20">所属门店：{{form.shopName}}</span>
        <span class="mr-20">订单编号：{{form.orderId}}</span>
        <span class="mr-20">订单类型：{{form.orderSourceName}}</span>
        <span class="mr-20 primary-color">订单状态：{{form.orderStatusName}}</span>
        <span class="mr-20">备注：{{form.remarks}}</span>
        <span class="mr-20">创建时间：{{form.orderTime}}</span>
        <p class="mt-10" v-if="form.memberId">
          <span class="mr-20">客户姓名：{{form.memberName}}</span>
          <span class="mr-20">手机号：{{form.mobile}}</span>
          <span class="mr-20">会员卡号：{{form.memberInfo.memberCode}}</span>
        </p>
      </div>
      <template slot="btns">
        <a-button class="mr-10" type="primary" icon="money-collect" v-if="form.orderStatus === 1" @click="handlePay">收款</a-button>
        <a-button class="mr-10" type="danger" icon="money-collect" v-if="form.orderStatus === 4" @click="handleShowRefund">退单</a-button>
      </template>
      <div class="form-bg" slot="content">
        <a-card class="mb-20" title="商品信息">
          <div class="p-10">
            <a-table bordered :columns="columns" :dataSource="form.unionList">
              <template slot="footer" slot-scope="row">
                <div class="">
                  <span class="mr-10">商品总数量：{{form.itemCount}} </span>
                  <span class="text-bold primary-color">合计金额：￥{{form.amount}} RM</span>
                </div>
              </template>
            </a-table>
          </div>
        </a-card>
        <a-card class="mb-20" title="收款信息">
          <div class="p-10">
            <a-descriptions title="" :column="3">
              <a-descriptions-item label="应收金额">
                {{form.amount}} RM
              </a-descriptions-item>
              <a-descriptions-item label="优惠金额">
                {{form.amountDiscount}} RM
              </a-descriptions-item>
              <a-descriptions-item>
                <span class="text-bold primary-color">实收金额：￥{{form.amountPay}} RM</span>
              </a-descriptions-item>
              <a-descriptions-item label="收款方式">
                {{form.payMethodName}}
              </a-descriptions-item>
              <a-descriptions-item label="收款明细">
                <template v-for="item in form.payItems">
                  {{item.payMethodName}}: {{item.amountPaid}} RM
                </template>
              </a-descriptions-item>
              <a-descriptions-item label="备注">
                {{form.discountWayRamarks}}
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-card>
        <a-card class="mb-20" title="值车码信息">
          <div class="p-10">
            <a-list :data-source="form.orderPlayItems" :grid="{ gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 6, xxl: 3 }" >
              <a-list-item slot="renderItem"  slot-scope="item, index" style="padding-bottom: 20px;">
                <a-list-item-meta :description="'状态：' + item.playStatusName" + index>
                  <a slot="title" href="javascript:;">{{ item.kartClassifyName }} x {{item.quantity || 0}}</a>
                  <canvas slot="avatar" :ref="'id'+item.orderKartPlayId" style="border: 1px solid #ddd;"></canvas>
                </a-list-item-meta>
                <div class="mt-10">
                  <p>{{item.orderKartPlayId}}</p>
                </div>
                <div class="mt-10" v-if="item.playStatusName === '已上车'">
                  <p>发车时间：{{item.startTime}}</p>
                </div>
              </a-list-item>
            </a-list>
          </div>
        </a-card>
      </div>
    </form-frame>

    <!-- 收款弹出窗 开始 -->
    <payment-modal v-model="payVisible" :data="form" @on-ok="handlePayOk"></payment-modal>
    <!-- 收款弹出窗 结束 -->

    <!-- 退单弹出窗 开始 -->
    <a-modal
      title="退单"
      :visible="refundVisible"
      @ok="handleRefund"
      @cancel="refundVisible = false"
    >
      <p>确定要退单么？</p>
    </a-modal>
    <!-- 退单弹出窗 结束 -->

  </div>
</template>

<script>
import FormFrame from '@/components/frame/FormFrame.vue';
import PaymentModal from '@/components/PaymentModal.vue';
import { orderInfo, orderRefund } from '@/api/order';
import {
  wxUrl,
  weAppPath,
} from '@/libs/config';
import formMixin from '@/mixin/formMixin';
import QRCode from 'qrcode';
import { getLocal } from '../../libs/utils';

export default {
  name: 'orderForm',
  mixins: [formMixin],
  components: {
    FormFrame,
    PaymentModal
  },
  props: {},
  data() {
    return {
      form: {},
      payVisible: false,
      refundVisible: false,
      columns: [
        {
          title: '商品名称',
          dataIndex: 'name',
          key: 'name',
          ellipsis: true,
          width: 200
        },
        {
          title: '单价',
          dataIndex: 'price',
          key: 'price',
          width: 80,
        },
        {
          title: '数量',
          dataIndex: 'quantity',
          key: 'quantity',
          ellipsis: true,
          width: 80,
        },
        {
          title: '金额',
          dataIndex: 'amount',
          key: 'amount',
          ellipsis: true,
          width: 80,
          customRender: (text, row, index) => {
            return (row.quantity * row.price).toFixed(2);
          }
        },
      ],
    };
  },
  computed: {
    orderStatus() {
      return this.$store.state.publicParams.orderStatus;
    }
  },
  watch: {},
  created() {
    if (this.paramsId) {
      this.getData(this.paramsId);
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    getData(id) {
      orderInfo(id).then((res) => {
        const { orderItems, productItems } = res;
        orderItems && orderItems.forEach((item) => {
          item.mId = item.kartClassifyId;
          item.name = item.kartClassifyName;
        });
        productItems && productItems.forEach((item) => {
          item.mId = item.productId;
          item.price = item.salePrice;
        });
        res.unionList = [...orderItems, ...productItems];
        this.form = res;
        this.$nextTick(() => {
          this.generateQRCode();
        });
      }, error => {
        console.log(error);
      });
    },

    // 生成二维码
    generateQRCode() {
      const storeInfo = getLocal('storeInfo');
      const isWeAppShop = storeInfo.wxMiniAppFlag;

      this.form.orderPlayItems.forEach((item) => {
        const domId = `id${item.orderKartPlayId}`;
        const codeItem = this.$refs[domId];
        let codeData = wxUrl + item.orderKartPlayId;

        // 需要跳转到小程序到门店
        if (isWeAppShop) {
          const query = `?shopId=${storeInfo.shopId}&shopName=${storeInfo.shopName}&playCode=`;
          codeData = weAppPath + query + item.orderKartPlayId;
        }

        QRCode.toCanvas(codeItem, codeData, {
          width: 64,
        }, (error) => {
          error && this.$message.error(error);
        });
      });
    },

    // 收款
    handlePay() {
      this.payVisible = true;
    },

    // 退款弹出层
    handleShowRefund() {
      this.refundVisible = true;
    },

    // 退款
    handleRefund() {
      const params = {
        orderId: this.form.orderId,
      };

      orderRefund(params).then(() => {
        this.refundVisible = false;
        this.getData(this.paramsId);
        this.$message.success('操作成功');
      });
    },

    // 支付成功
    handlePayOk() {
      this.getData(this.paramsId);
    }
  }
};
</script>

<style lang="less" scoped>
</style>
